<template>
  <v-container grid-list-lg>
    <v-radio-group v-model="radio">
      <div class="flex">
        <v-radio
          v-for="(value, index) in subscriptions"
          :key="index"
          :value="value.name"
          class="w-full"
          color="grey darken-1"
        >
          <template v-slot:label>
            <v-card elevation="2" width="95%">
              <v-card-title primary-title>
                {{ value.name }}
              </v-card-title>
              <v-card-text>
                {{ value.description }}
              </v-card-text>
            </v-card>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
    <h1 class="blue-grey--text nunito lighten-3"><b>Expiry</b></h1>
    <v-divider class="my-2"></v-divider>
    <p class="text-h6 green--text" v-if="isSuccess">
      Merchant's subscription expiry date has been updated
    </p>
    <div style="width: 80%" class="flex justify-center">
      <v-date-picker
        v-model="selectedDate"
        class="mt-4"
        min="2016-06-15"
        landscape
        color="info"
        full-width
      ></v-date-picker>
    </div>
    <div class="flex justify-end">
      <v-btn color="success" @click="save">save</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "Subscription",

  data() {
    return {
      isSuccess: false,
      radio: null,
      selectedDate: null,
      subscriptions: [
        {
          name: "Basic",
          description: "basic features",
          price: 0,
          duration_type: "free",
          duration_period: 0,
          status: false,
          startPeriod: "",
          endPeriod: "",
        },
        {
          name: "Premium",
          description: "More features than basic",
          price: 15,
          duration_type: "month",
          duration_period: 1,
          status: true,
          startPeriod: "2021-04-20T07:14:05.572Z",
          endPeriod: "2021-04-20T07:14:05.572Z",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  methods: {
    handleSubs() {
      let handler = [];
      this.subscriptions.forEach((e) => {
        if (e.name == this.radio) {
          e.status = true;
          handler.push(e);
        } else {
          e.status = false;
          handler.push(e);
        }
        this.subscriptions = handler;
        // console.log(this.subscriptions);
      });
    },

    save() {
      // console.log("date", this.selectedDate);
      this.$store.dispatch("startLoad");
      this.$store
        .dispatch("updateSubs", { expired_at: this.selectedDate })
        .then((r) => {
          if (r.data.status == "success") {
            this.isSuccess = true;
          }
        });
    },
  },
  beforeMount() {
    this.selectedDate = dayjs(this.merchant_detail.expired_at).format(
      "YYYY-MM-DD"
    );
    let x = this.subscriptions.find((e) => e.status == true);
    this.radio = x.name;
  },
  beforeUpdate() {
    let handler = [];
    this.subscriptions.forEach((e) => {
      if (e.name == this.radio) {
        e.status = true;
        handler.push(e);
      } else {
        e.status = false;
        handler.push(e);
      }
      this.subscriptions = handler;
      // console.log(this.subscriptions);
    });
  },
};
</script>
